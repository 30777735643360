import { useEffect, useState } from 'react';
import { ManageWorkerAnalytics } from './ManageWorkerAnalytics';
import TableChartIcon from '@mui/icons-material/TableChart';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import AnalyticsDashboard from './Dashboard';
import { Typography, IconButton, Grid, Box, TextField, Button, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import dayjs from 'dayjs';
import { fetchAllMonthlyAnalytics, fetchAllWorkerAnalytics } from '../../services/workeranalytics.service';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { fetchAllProjectAnalytics, getAllActiveProjects } from '../../services/project.service';
import { getTotalNumberOfWorkers } from '../../services/workdone.service';
import { getOffset } from '../../utils/utilityFunctions';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
	container: {
		flex: 1,
		width: '100%',
		display: 'flex',
	},
	header: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	iconContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	separator: {
		fontSize: 30,
	},
	icon: {
		fontSize: '30px',
	},
	activeIcon: {
		color: 'blue',
	},
	inactiveIcon: {
		color: 'black',
	},
});

const WorkerAnalytics = () => {
	const styles = useStyles();
	const history = useHistory();
	const location = useLocation();

	const [isDashboard, setIsDashboard] = useState<boolean>(false);
	const [toDate, setToDate] = useState<string>(moment().format('YYYY-MM-DD'));
	const [fromDate, setFromDate] = useState<string>(moment().startOf('month').format('YYYY-MM-DD'));
	const [page, setPage] = useState(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [workerAnalyticsTotalLength, setWorkerAnalyticsTotalLength] = useState<number>(0);
	const [workerAnalyticsData, setWorkerAnalyticsData] = useState<any>([]);
	const [projectAnalyticsData, setProjectAnalyticsData] = useState<any>([]);
	const [monthlyAnalyticsData, setMonthlyAnalyticsData] = useState<any>([]);
	const [pageSize, setPageSize] = useState<number>(10);
	const [revenue, setRevenue] = useState<number>(0);
	const [expenses, setExpenses] = useState<number>(0);
	const [totalProfitLoss, setTotalProfitLoss] = useState<number>(0);
	const [monthsOfSalaryPaid, setMonthsOfSalaryPaid] = useState<number>(0);
	const [noOfActiveProjects, setNoOfActiveProjects] = useState<number>(0);
	const [totalNoOfWorkers, setTotalNoOfWorkers] = useState<number>(0);
	const [pastNoOfMonths, setPastNoOfMonths] = useState<number>(3);

	const handleDashboardClick = () => {
		setIsDashboard(true);
		history.push('?view=dashboard');
	};

	const handleWorkerAnalyticsClick = () => {
		setIsDashboard(false);
		history.push('?view=detailed');
	};

	const getMonthDifference = (startDate, endDate) => {
		const start = new Date(startDate);
		const end = new Date(endDate);

		// Check if the dates are within the same month and year
		if (start.getFullYear() === end.getFullYear() && start.getMonth() === end.getMonth()) {
			return 1;
		}

		// Calculate the month difference
		const months = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());

		return months + 1; // Add 1 to include the start month
	};

	useEffect(() => {
		setFromDate(moment().startOf('month').format('YYYY-MM-DD'));
		setToDate(moment().format('YYYY-MM-DD'));

		const query = new URLSearchParams(location.search);
		const view = query.get('view');
		if (view === 'dashboard') {
			setIsDashboard(true);
		} else if (view === 'detailed') {
			setIsDashboard(false);
		} else {
			// If no view is specified, default to the dashboard and update the URL
			setIsDashboard(true);
			history.replace('?view=dashboard');
		}
	}, [location.search]);

	const getDayDifference = (startDate: string | number | Date, endDate: string | number | Date): number | null => {
		const start = new Date(startDate);
		const end = new Date(endDate);

		if (isNaN(start.getTime()) || isNaN(end.getTime())) {
			return null;
		}

		const differenceInMilliseconds = end.getTime() - start.getTime();

		// Convert milliseconds to days
		const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

		return Math.ceil(differenceInDays);
	};

	const loadDataToGrid = async (pageNumber?: number, size?: number) => {
		try {
			setLoading(true);
			let result: any;
			let tmpList = [];
			let projectTmpList = [];
			let monthlyAnalyticsTmpList = [];
			let totalRevenue = 0;
			let totalExpenses = 0;
			let totalProfitLoss = 0;
			let noOfActiveProjects = 0;
			let totalNoOfWorkers = 0;
			result = await fetchAllWorkerAnalytics(
				moment(fromDate)
					.startOf('day')
					.utcOffset(getOffset() * -1)
					.format('YYYY-MM-DD HH:mm:ss'),
				moment(toDate)
					.startOf('day')
					.utcOffset(getOffset() * -1)
					.format('YYYY-MM-DD HH:mm:ss'),
				pageNumber,
				size
			);

			if (pageNumber && size) {
				result = await fetchAllWorkerAnalytics(
					moment(fromDate).format('YYYY-MM-DD'),
					moment(toDate).utcOffset(0, true).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
					pageNumber,
					size
				);

				setWorkerAnalyticsTotalLength(Number(result?.data?.data?.totalItems));

				for (let i = 0; i < result?.data?.data?.rows?.length; i++) {
					const info = result.data?.data?.rows[i];
					const row = {
						id: info.id,
						workerName: info.name,
						workDoneEntriesCount: info.totalWorkDoneEntries,
						salary: Number(info.salary) || 0,
						expenses: Number(info.expenses) || 0,
						revenue: Number(info.revenue) || 0,
					};
					tmpList.push(row); // Add row to temporary array
				}
			} else {
				const result = await fetchAllWorkerAnalytics(
					moment(fromDate).format('YYYY-MM-DD'),
					moment(toDate).utcOffset(0, true).endOf('day').format('YYYY-MM-DD HH:mm:ss')
				);
				const projectAnalyticsResult = await fetchAllProjectAnalytics(
					moment(fromDate).format('YYYY-MM-DD'),
					moment(toDate).utcOffset(0, true).endOf('day').format('YYYY-MM-DD HH:mm:ss')
				);
				const monthlyAnalyticsResult = await fetchAllMonthlyAnalytics(pastNoOfMonths);
				const allActiveProjects = await getAllActiveProjects();
				noOfActiveProjects = allActiveProjects.data.data[0].activeProjects;

				const totalNumberOfWorkers = await getTotalNumberOfWorkers();
				totalNoOfWorkers = totalNumberOfWorkers.data.data[0].allWorkers;

				for (let i = 0; i < result?.data?.data?.rows?.length; i++) {
					const info = result.data?.data?.rows[i];
					const row = {
						id: info.id,
						workerName: info.name,
						workDoneEntriesCount: info.totalWorkDoneEntries,
						salary: Number(info.salary) || 0,
						expenses: Number(info.expenses) || 0,
						revenue: Number(info.revenue) || 0,
						profitLoss: Number(info.revenue) - (Number(info.expenses) || 0),
					};
					tmpList.push(row);
					totalRevenue += row.revenue;
					totalExpenses += row.expenses;
					totalProfitLoss += row.profitLoss;
				}
				for (let i = 0; i < projectAnalyticsResult?.data?.data?.rows?.length; i++) {
					const info = projectAnalyticsResult.data?.data?.rows[i];
					const row = {
						projectName: info.name,
						totalSellingPrice: info.totalSellingPrice,
					};
					projectTmpList.push(row);
				}
				for (let i = 0; i < monthlyAnalyticsResult?.data?.data?.rows?.length; i++) {
					const info = monthlyAnalyticsResult.data?.data?.rows[i];
					const [year, month] = info.month.split('-');

					const row = {
						year: year,
						month: month,
						totalRevenue: parseInt(info.total_revenue),
						totalExpenses: info.total_expenses,
						totalProfits: info.total_profit,
					};
					monthlyAnalyticsTmpList.push(row);
				}

				const monthsOfSalaryPaid = getMonthDifference(fromDate, toDate);
				totalProfitLoss = totalProfitLoss * monthsOfSalaryPaid;
				totalExpenses = totalExpenses * monthsOfSalaryPaid;
				setMonthsOfSalaryPaid(monthsOfSalaryPaid);
				setProjectAnalyticsData([...projectTmpList]);
				setWorkerAnalyticsData([...tmpList]);
				setMonthlyAnalyticsData([...monthlyAnalyticsTmpList]);
				setRevenue(totalRevenue);
				setExpenses(totalExpenses);
				setTotalProfitLoss(totalProfitLoss);
				setNoOfActiveProjects(noOfActiveProjects);
				setTotalNoOfWorkers(totalNoOfWorkers);
			}

			// Set state after processing all rows
			setWorkerAnalyticsData([...tmpList]);
			setLoading(false);
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
			setLoading(false);
		}
	};

	const handleUpdateData = () => {
		setPage(0);
		loadDataToGrid(0, pageSize);
	};

	return (
		<div className={styles.container}>
			<div className={styles.root}>
				<div className={styles.header}>
					<Typography variant='h4'>Worker Analytics</Typography>
					<div className={styles.iconContainer}>
						<IconButton onClick={handleDashboardClick}>
							<DashboardRoundedIcon
								className={`${styles.icon} ${isDashboard ? styles.activeIcon : styles.inactiveIcon}`}
							/>
						</IconButton>
						<Typography className={styles.separator}>|</Typography>
						<IconButton onClick={handleWorkerAnalyticsClick}>
							<TableChartIcon className={`${styles.icon} ${!isDashboard ? styles.activeIcon : styles.inactiveIcon}`} />
						</IconButton>
					</div>
				</div>
				<Grid container sx={{ paddingTop: 2, paddingBottom: 2, width: '100%' }}>
					<Grid item xs={12} paddingTop={2} paddingBottom={2}>
						<Divider>Work Done Period</Divider>
					</Grid>
					<Grid item xs={12} sm={5}>
						<Box sx={{ width: '100%', height: '50px' }}>
							<TextField
								label='From'
								type='date'
								sx={{ width: '80%' }}
								value={fromDate}
								onChange={(e) => setFromDate(e.target.value)}
								inputProps={{
									max: dayjs(toDate).format('YYYY-MM-DD'),
								}}
								InputLabelProps={{ shrink: true }}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} sm={2}>
						<Typography variant='subtitle1' textAlign={'center'} sx={{ marginTop: '15px', width: '50%' }}>
							to
						</Typography>
					</Grid>
					<Grid item xs={12} sm={5}>
						<Box style={{ display: 'flex' }}>
							<TextField
								label='To'
								type='date'
								sx={{ width: '100%' }}
								value={toDate}
								onChange={(e) => {
									setToDate(e.target.value);
								}}
								inputProps={{
									min: dayjs(fromDate).format('YYYY-MM-DD'),
									max: dayjs().format('YYYY-MM-DD'),
								}}
								InputLabelProps={{ shrink: true }}
							/>
							<Button
								sx={{ marginLeft: '15px' }}
								variant='outlined'
								size='small'
								color='primary'
								onClick={handleUpdateData}>
								Update Data
							</Button>
						</Box>
					</Grid>
				</Grid>
				<Divider style={{ marginTop: 10, marginBottom: 20 }} />
				{isDashboard ? (
					<AnalyticsDashboard
						loadDataToGrid={() => loadDataToGrid()}
						fromDate={fromDate}
						toDate={toDate}
						loading={loading}
						setLoading={setLoading}
						workerAnalyticsData={workerAnalyticsData}
						workerAnalyticsTotalLength={workerAnalyticsTotalLength}
						revenue={revenue}
						expenses={expenses}
						totalProfitLoss={totalProfitLoss}
						monthsOfSalaryPaid={monthsOfSalaryPaid}
						projectAnalyticsData={projectAnalyticsData}
						noOfActiveProjects={noOfActiveProjects}
						totalNoOfWorkers={totalNoOfWorkers}
						monthlyAnalyticsData={monthlyAnalyticsData}
					/>
				) : (
					<ManageWorkerAnalytics
						loadDataToGrid={(page: number, pageSize: number) => loadDataToGrid(page, pageSize)}
						page={page}
						setPage={setPage}
						pageSize={pageSize}
						setPageSize={setPageSize}
						fromDate={fromDate}
						toDate={toDate}
						loading={loading}
						setLoading={setLoading}
						workerAnalyticsData={workerAnalyticsData}
						workerAnalyticsTotalLength={workerAnalyticsTotalLength}
					/>
				)}
			</div>
		</div>
	);
};

export default WorkerAnalytics;
