import React, { useState, useRef, useEffect } from 'react';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem } from '@mui/material';
import { EType } from '../../models/user-redux.model';
import { useAppSelector } from '../../hooks/redux-hooks';
import { addNewUser, editUser } from '../../services/user.service';

type U = keyof typeof EType;

export const AddEditUserDialog = (props: any) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [userType, setUserType] = useState<any>(EType.admin);
	const user = useAppSelector((state) => state.user.user);
	const newUserStatusSchema = yup.object().shape({
		name: yup.string().required(),
		email: yup.string().required(),
		mobileNumber: yup.string().required(),
		type: yup.string().required(),
		salary: yup.string(),
		password: yup.string().notRequired(),
		confirmPassword: yup.string(),
		otherCosts: yup.string(),
	});
	const nameInputRef: any = useRef();
	const emailInputRef: any = useRef();
	const phoneInputRef: any = useRef();
	const salaryInputRef: any = useRef();
	const passwordInputRef: any = useRef();
	const confirmPasswordInputRef: any = useRef();
	const otherCostsInputRef: any = useRef();

	const onClose = () => {
		setLoading(false);
		props.close();
	};

	const handleSubmit = async () => {
		let payload = {
			name: nameInputRef?.current?.value,
			email: emailInputRef?.current?.value,
			mobileNumber: phoneInputRef?.current?.value,
			salary: Number(salaryInputRef?.current?.value),
			password: passwordInputRef?.current?.value,
			otherCosts: Number(otherCostsInputRef?.current?.value),
			type: userType,
		};

		setLoading(true);
		newUserStatusSchema
			.validate(payload, { context: { isEditMode: props.isEditMode } })
			.then(async (value) => {
				const result: any = await addNewUser('add/newUser?adminId=' + user?.id, payload);

				if (result.data.success) {
					toast.success('User added successfully');
					props.onAdded();
					onClose();
				} else {
					toast.error(result.data.message);
				}
				setLoading(false);
			})
			.catch((err: any) => {
				handleValidationErrors(err);
				setLoading(false);
			});
	};

	const handleUpdate = async () => {
		const enteredPassword = passwordInputRef?.current?.value;
		const enteredConfirmPassword = confirmPasswordInputRef?.current?.value;

		let payload = {
			name: nameInputRef?.current?.value,
			email: emailInputRef?.current?.value,
			mobileNumber: phoneInputRef?.current?.value,
			salary: Number(salaryInputRef?.current?.value),
			password: passwordInputRef?.current?.value,
			otherCosts: Number(otherCostsInputRef?.current?.value),
			type: userType,
		};

		// Include password field in the payload only if not blank
		if (enteredPassword !== '') {
			// Check if password and confirm password match
			if (enteredPassword !== enteredConfirmPassword) {
				toast.error('Password and Confirm Password do not match.');
				return;
			}
			if (enteredPassword.length < 4) {
				toast.error('Password must be at least 4 characters.');
				return;
			}
			payload.password = enteredPassword;
		} else {
			payload.password = undefined;
		}

		setLoading(true);
		newUserStatusSchema
			.validate(payload, { context: { isEditMode: props.isEditMode } })
			.then(async (value) => {
				const result: any = await editUser('update/profile?userId=' + props.selectedUserForEdit?.id, payload);

				if (result.data.success) {
					toast.success('User updated successfully');
					props.refreshPanel();
					onClose();
				} else {
					toast.error(result.data.message);
				}
				setLoading(false);
			})
			.catch((err: any) => {
				handleValidationErrors(err);
				setLoading(false);
			});
	};

	const handleValidationErrors = (err: any) => {
		if (err.errors) {
			// Display specific error messages
			err.errors.forEach((error: string) => {
				toast.error(error);
			});
		} else {
			toast.error(err?.response?.data?.message || err?.message);
		}
	};

	useEffect(() => {
		if (props.isEditMode) {
			setUserType(EType[props.selectedUserForEdit?.type as keyof typeof EType]);
		}
	}, [props.isEditMode]);

	return (
		<Dialog open={props.show} onClose={onClose} aria-labelledby='customized-dialog-title'>
			<DialogTitle>{props.isEditMode ? 'Edit' : 'Add'} User</DialogTitle>
			<DialogContent dividers style={{ width: '400px' }}>
				<div>
					<TextField
						style={{ marginTop: '2%' }}
						label='Name'
						variant='standard'
						defaultValue={props.isEditMode ? props?.selectedUserForEdit?.name : null}
						inputRef={nameInputRef}
						fullWidth
					/>
				</div>
				<div style={{ marginTop: '10px' }}>
					<TextField
						style={{ marginTop: '2%' }}
						defaultValue={props.isEditMode ? props?.selectedUserForEdit?.email : null}
						label='Email'
						variant='standard'
						inputRef={emailInputRef}
						fullWidth
					/>
				</div>
				<div style={{ marginTop: '10px' }}>
					<TextField
						style={{ marginTop: '2%' }}
						defaultValue={props.isEditMode ? props?.selectedUserForEdit?.mobileNumber : null}
						label='Phone'
						variant='standard'
						inputRef={phoneInputRef}
						fullWidth
					/>
				</div>
				<div style={{ marginTop: '10px' }}>
					<TextField
						style={{ marginTop: '2%' }}
						defaultValue={
							props.isEditMode && Number(props?.selectedUserForEdit?.salary) > 0
								? props?.selectedUserForEdit?.salary
								: null
						}
						label='Salary (Daily)'
						variant='standard'
						type='number'
						InputProps={{
							inputProps: { min: 0 },
						}}
						inputRef={salaryInputRef}
						fullWidth
					/>
				</div>
				{props.isEditMode && (
					<>
						<div style={{ marginTop: '10px' }}>
							<TextField
								style={{ marginTop: '2%' }}
								label='Password'
								type='password'
								variant='standard'
								inputRef={passwordInputRef}
								fullWidth
							/>
						</div>
						<div style={{ marginTop: '10px' }}>
							<TextField
								style={{ marginTop: '2%' }}
								label='Confirm Password'
								type='password'
								variant='standard'
								inputRef={confirmPasswordInputRef}
								fullWidth
							/>
						</div>
					</>
				)}
				<div style={{ marginTop: '10px' }}>
					<TextField
						style={{ marginTop: '2%' }}
						defaultValue={
							props.isEditMode && Number(props?.selectedUserForEdit?.otherCosts) > 0
								? props?.selectedUserForEdit?.otherCosts
								: null
						}
						label='Other Costs'
						variant='standard'
						type='number'
						InputProps={{
							inputProps: { min: 0 },
						}}
						inputRef={otherCostsInputRef}
						fullWidth
					/>
				</div>
				<div style={{ marginTop: '30px' }}>
					<Select
						value={userType}
						onChange={(e: any) => {
							setUserType(EType[e.target.value as keyof typeof EType]);
						}}
						label='User Type'
						fullWidth
						variant='standard'>
						{Object.keys(EType) &&
							Object.keys(EType).map((userType: any, index: number) => (
								<MenuItem key={index} value={EType[userType as U]}>
									{userType}
								</MenuItem>
							))}
					</Select>
				</div>
			</DialogContent>
			<DialogActions>
				<Button variant='outlined' size='small' onClick={onClose}>
					Cancel
				</Button>
				<Button type='submit' size='small' variant='contained' onClick={props.isEditMode ? handleUpdate : handleSubmit}>
					{props.isEditMode ? 'Update' : 'Add'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
